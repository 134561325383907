<template>
  <section class="shipping-returns">
    <h2 class="shipping-returns__title text-title">Shipping & Returns</h2>
    <p class="shipping-returns__sub-title text-link">Terms of Sale & Refunds</p>
    <p class="shipping-returns__text text-base">
      These terms of sale apply to all offers made by browngrotta arts, all purchases and orders
      placed by you (excluding commissioned work that has been custom ordered by you) and all
      agreements concluded between us in relation to the art or products and services offered on
      this website.
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">
      Overall Return Rights:
    </p>
    <p class="shipping-returns__text text-base">
      Our General Return Policy. You may return items purchased at browngrotta.com or received as a
      gift within 14 business days of receiving them.
    </p>
    <p class="shipping-returns__text text-base">
      One important exception that applies to all consumers including EU and UK consumers: Since all
      commissioned artwork is custom ordered to reflect client requirements, commission artwork may
      not be returned.
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">
      Right to Cancel within the EU and the UK:
    </p>
    <p class="shipping-returns__text text-base">
      If you are ordering from within the EU or the UK, you have a 14-day period to cancel the sale
      for any reason and obtain a full refund. If the artwork has been shipped before you notify
      browngrotta arts of your cancellation then your refund will be processed within 14 days of
      browngrotta arts receiving your returned item. Again, the 14-day cancellation period does not
      apply to commissioned artwork that has been custom ordered by you.
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">
      The Return Process
    </p>
    <p class="shipping-returns__text text-base">
      Contact us by either calling 1-203-834-0623 or e-mailing us at art@browngrotta.com to obtain
      return instructions.
    </p>
    <p class="shipping-returns__text text-base">
      Repack the product in its original packaging materials. If the package's condition is not
      suitable for a safe return back to us then provide your own return packaging. EU consumers
      will be refunded for the cost of their return packaging.
    </p>
    <p class="shipping-returns__sub-title text-link">
      All consumers:
      <span class="shipping-returns__text text-base">
        You will be responsible for artwork damaged in return transit.
      </span>
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">
      Shipping Costs
    </p>
    <p class="shipping-returns__text text-base">
      General consumers: You will be responsible for shipping costs both ways if you return an item
      that is not defective or has not been received by mistake. If you return a product because it
      was defective or damaged or because we shipped you something that you did not order,
      browngrotta arts will pay the return-shipping costs.
    </p>
    <p class="shipping-returns__sub-title text-link">
      EU and UK consumers:
      <span class="shipping-returns__text text-base">You will be refunded all shipping costs.</span>
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">
      Damaged in Transit
    </p>
    <p class="shipping-returns__text text-base">
      If your package appears damaged, or, if once it is opened, you find damaged artwork, call us
      at 203-834-0623, so we can arrange to have it picked up and inspected. Please retain all
      original packing materials. You may be responsible for artwork damaged in return transit.
      <br />
      Note: Depending on the circumstances, we may not provide a refund if you return items to us
      that have been damaged, altered or worn.
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">Refunds</p>
    <p class="shipping-returns__text text-base">
      You can expect a refund, if applicable, in the same form of payment originally used for
      purchase within 14 business days after browngrotta arts receives your return, providing it
      arrives safely.
    </p>
    <p class="shipping-returns__text text-base">
      We will notify you of the status of your return by e-mail or postal mail. If you have any
      questions, please call us 203-834-0623, Monday-Friday 9am-6pm, EST or contact us by email at
      art@browngrotta.com.
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">
      Product Descriptions
    </p>
    <p class="shipping-returns__text text-base">
      We attempt to describe our art and products offered as accurately as possible. However, we do
      not warrant that art or product descriptions or other content of the website are 100%
      accurate, complete, reliable, current or error-free. If a piece of art or product offered by
      us and purchased by you is not as described (excluding commissioned work that has been custom
      ordered) then your sole remedy is to return it in unused condition for a conforming
      replacement, refund or credit.
    </p>

    <p class="shipping-returns__sub-title shipping-returns__new-paragraph text-link">Disclaimer</p>
    <p class="shipping-returns__text text-base">
      We are not liable for any financial damage (including but not limited to the cost of any
      replacement purchase) that may occur because of non-timely arrival or any lack of availability
      or conformity of the product.
    </p>

    <p class="shipping-returns__sub-title text-link">
      Last Posted: <span class="shipping-returns__text text-base">May 2022</span>
    </p>

    <IconButton
      class="shipping-returns__close button--md button--outline-white"
      @click="$router.go(-1)"
    >
      <IconClose width="18" height="18"></IconClose>
    </IconButton>
  </section>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: {
    IconButton,
    IconClose,
  },
  head: {
    title: function () {
      return {
        inner: "Shipping & Returns browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Shipping & Returns browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Shipping & Returns browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Shipping & Returns browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Shipping & Returns browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Shipping & Returns browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Shipping & Returns browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Shipping & Returns browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Shipping & Returns browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-returns {
  position: relative;
  padding: 17rem 31.5rem 18rem;
  @media screen and (max-width: $md) {
    padding: 17rem 10.5rem 18rem;
  }
  @media screen and (max-width: $xs) {
    padding: 17rem 25px 18rem;
  }
  &__title {
    font-size: 4rem;
    line-height: 1.25;
    letter-spacing: -1.33px;
    margin-bottom: 5.5rem;
  }
  &__sub-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  &__text {
    font-weight: 100;
    color: rgba($color: $white, $alpha: 0.8);
    margin-bottom: 3rem;
    & > span {
      font-weight: 400;
    }
  }
  &__new-paragraph {
    margin-top: 3rem;
  }

  &__close {
    position: absolute;
    top: 4.5rem;
    right: 10.5rem;
    z-index: 1000;
    @media screen and (max-width: $md) {
      right: 5rem;
    }
    @media screen and (max-width: $xs) {
      right: 25px;
    }
  }
}
</style>
